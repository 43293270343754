import { Exercise, PlanificationDay, Program, WorkoutWeekDraft } from "../api";
type  RepsAndSets = {
  reps: Array<string>;
  sets: number;
}

export const extractWorkoutWeekFromText = (text: string) => {
  const regex = /```json\s*(.*?)```/s;
  const match = text.match(regex);

  let workoutWeek: WorkoutWeekDraft | undefined = match?.length ? JSON.parse(match[1]) : undefined;
  if (!workoutWeek && text.indexOf('{') === 0) {
    try {
      workoutWeek = JSON.parse(text);
      console.log('workoutWeek', workoutWeek);
      if (workoutWeek?.planification) {
        workoutWeek.planification = stubPlanificationExercises(workoutWeek.planification);
      }
    }catch (e) {
      console.error(e);
    }

    return {
      msg: '',
      workoutWeek,
    }
  }
  return {
    msg: text.replace(regex, ''),
    workoutWeek,
  }
}

export const stubPlanificationExercises = (planification: PlanificationDay[]): PlanificationDay[] => {
  return planification.map((day: any) => {
    return {
      ...day,
      exercises: day.exercises.map((exercise: Exercise, originalIndex: number) => {
        return {
          ...exercise,
          id: originalIndex,
          ...getRepsAndSets(exercise.reps, exercise.sets),
        }
      })
    }
  });
};

export const extractExerciseFromText = (text: string) => {
  const regex = /```json\s*(.*?)```/s;
  const match = text.match(regex);

  let exercise: Exercise | undefined = match?.length ? JSON.parse(match[1]) : undefined;
  if (!exercise && text.indexOf('{') === 0) {
    try {
      exercise = JSON.parse(text);
    }catch (e) {
      console.error(e);
    }

  }
  return {
    msg: text.replace(regex, ''),
    exercise: {
      ...exercise,
      ...getRepsAndSets(exercise?.reps, exercise?.sets || 0),
    },
  }
}

export const getRepsAndSets = (reps: any, series: number): RepsAndSets => {
  if (typeof reps === 'string') {
    const r = reps.split('-');
    if (r.length > 1) {
      return {
        reps: r,
        sets: r.length,
      }
    }
    const re = Array(series).fill(reps);
    return {
      reps: re,
      sets: re.length,
    }
  }
  return {
    reps,
    sets: reps.length,
  };
}

export const repsToString = (reps: any): string => {
  if (Array.isArray(reps)) {
    return reps.join('-');
  }
  return reps;
}

export const extractDomaProgramFromText = (text: string) => {
  const regex = /```json\s*(.*?)```/s;
  const match = text.match(regex);

  const program: Program | undefined = match?.length ? JSON.parse(match[1]) : undefined;
  return {
    msg: text.replace(regex, ''),
    program,
  }
}

export const extracThumbnailFromYoutubeLink = (link?: string) => {
  if (!link) {
    return '';
  }
  const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = link.match(regex);
  return match ? `https://img.youtube.com/vi/${match[1]}/mqdefault.jpg` : '';
}
