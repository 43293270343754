import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Skeleton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import api, { Gym } from '../../api';
import { useRef, useState } from 'react';

export const GymCard = ({ gym, sx, canEdit, logoRef }: { gym: Gym, sx?: any, canEdit?: boolean, logoRef?: React.RefObject<HTMLDivElement> }) => {
  const { t } = useTranslation();
  const style = sx || { minWidth: 250, maxWidth: 345, minHeight: 230, position: 'relative' };
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const [gymImage, setGymImage] = useState<string | undefined>(gym.logoUrl);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileType = file.type;
      if (fileType === 'image/png' || fileType === 'image/jpeg') {
        setLoading(true);
        try {
          // Handle file upload logic here
          const res = await api.gyms.updateLogo(gym.id, file); // Assuming uploadImage is a function in api/index
          if (res.success) {
            setGymImage(res.data.logoUrl);
          }
        } catch (error) {
          console.error('Error uploading file:', error);
        } finally {
          setLoading(false);
        }
      } else {
        console.error('Invalid file type. Only PNG and JPG are allowed.');
      }
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <Card sx={style} ref={logoRef}>
      <CardContent>
        <Typography variant="h6" component="div">
          {gym.name}
        </Typography>
        <CardActionArea>
          {loading ? (
            <Skeleton variant="rectangular" width="100%" height={150} />
          ) : (
            <CardMedia
              component="img"
              loading="lazy"
              height="150"
              image={gymImage || '/logo_2_out.png'}
              sx={{ objectFit: 'contain' }}
            />
          )}
        </CardActionArea>
      </CardContent>

      <CardActions sx={{ bottom: 0, position: 'absolute' }}>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept="image/png, image/jpeg"
          onChange={handleFileChange}
        />
        <Button size="small" disabled={!canEdit || loading} onClick={handleButtonClick}>
          {t('Profile.editImage')}
        </Button>
      </CardActions>
    </Card>
  );
};