import React, { FC, ReactElement, useContext, useEffect, useState } from "react";
import {
  IconButton,
  Toolbar,
  Typography,
  AppBar,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Slide,
  Divider,
  useTheme,
} from "@mui/material";
import { AccountBoxOutlined, AssignmentOutlined, ClassOutlined, CreditCardOffOutlined, CreditCardOutlined, DynamicFeedOutlined, FitnessCenterOutlined, HailOutlined, LocalMallOutlined, Menu } from "@mui/icons-material";
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import InsightsIcon from '@mui/icons-material/Insights';
import { TransitionProps } from "@mui/material/transitions";

import Logo from "../icons/logoWizfit";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../contexts/userContext";
import { SessionContext } from "../contexts/sessionContext";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import api, { Subscription } from "../api";

type ListItemActionProps = {
  label: string;
  to: string;
  disabled?: boolean;
  id?: string;
  Icon: any;
}

export const ListItemAction = ({ label, to, disabled, Icon, id }: ListItemActionProps) => (
  <Link to={disabled ? '#' : to} id={id}>
    <ListItem disabled={disabled} disablePadding>
      <ListItemButton disabled={disabled}>
        <ListItemIcon>
          {Icon}
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  </Link>
)

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Navbar: FC = (): ReactElement => {
  const theme = useTheme();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [value, setValue] = useState("");
  const [isOpen, setIsOpen] = useState(true);
  const location = useLocation();
  const { user } = useContext(UserContext);
  const [hiddenNavbar, setHiddenNavar] = useState(false);
  const { trainingSession } = useContext(SessionContext);
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isProfessor = user.role === 'PROFESSOR';
  const isAdmin = user.professorRole === 'ADMIN';
  const isClient = user.role === 'CLIENT';
  const isLoggedIn = !!user.id;
  const open = !!trainingSession.id && location.pathname.indexOf('training_session') < 0 && isOpen;

  const handleClose = () => setIsOpen(false);
  const handleGo = () => navigate(`/training_sessions/${trainingSession.id}`)
  const getUserSubscriptions = async (userId: number) => {
    const res = await api.users.getSubscriptions(userId);
    if (res.success && res.data?.length) {
      setSubscription(res.data[0]);
    }
  };

  const handleDrawerOpen = () => setIsDrawerOpen(true);
  
  useEffect(() => {
    const hide = location.pathname === '/' || location.pathname.indexOf('sign-in') >= 0 || location.pathname.indexOf('signup') >= 0
    setHiddenNavar(!!hide);
  }, [location.pathname])

  useEffect(() => {
    if (user?.id) {
      getUserSubscriptions(user.id);
    }
  }, [user?.id])

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Atención"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t('Navbar.youHaveAnActiveTrainingSession')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleGo}>Ir</Button>
          <Button onClick={handleClose}>{t('Navbar.goLater')}</Button>
        </DialogActions>
      </Dialog>

      {!hiddenNavbar && (
        <>
          <AppBar position="fixed" sx={{ background: 'white' }} color="transparent">
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                id="navbar-burger"
                edge="start"
                sx={{
                  marginRight: 2,
                }}
              >
                <Menu />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} color="primary">
              WizFit
              </Typography>
              {/*<Button color="inherit">Login</Button>*/}
            </Toolbar>
          </AppBar>
          <SwipeableDrawer
            anchor="left"
            open={isDrawerOpen}
            onClose={() => setIsDrawerOpen(false)}
            onOpen={() => setIsDrawerOpen(true)}
            PaperProps={{ sx: { zIndex: 10002}} }
          >
            <Box display="flex" flexDirection="column" alignItems="center" sx={{ backgroundColor: theme.palette.primary.main, width: 300 }}>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 0 }}
              >
                <Logo fillColor="white" width="45" height="45" />
              </IconButton>
            </Box>
            {isLoggedIn ? (
              <>
              <Divider />
                <Typography sx={{ margin: '10px 20px' }} variant="h6">{t('Navbar.planification')}</Typography>
              <Divider />
              <List onClick={() => setIsDrawerOpen(false)} sx={{ bgcolor: 'background.paper', '> a': { textDecoration: 'none', color: 'inherit' } }}>
                <ListItemAction label={t('Navbar.clients')} Icon={<HailOutlined />} to="/clients" />
                <ListItemAction label={t('Navbar.planifications')} Icon={<AssignmentOutlined />} to="/workoutweeks" id="navbar-planifications"/>
                <ListItemAction label={t('Navbar.batch')} disabled={!subscription?.product?.metadata['batchPlanifications']} Icon={<DynamicFeedOutlined />} to="/multiple-trainings" />
                {isAdmin && <ListItemAction disabled={true} label="Conversaciones" Icon={<QuestionAnswerIcon />} to="/conversations" />}
              </List>

              <Divider />
              <Box hidden={true} >
                <Typography variant="h6" sx={{ margin: '10px 20px' }}>{t('Navbar.cardiacMonitoring')}</Typography>
                <Divider />
                <List onClick={() => setIsDrawerOpen(false)} sx={{ bgcolor: 'background.paper', '> a': { textDecoration: 'none', color: 'inherit' } }}>
                  {isProfessor && <ListItemAction disabled={true} label={t('Navbar.clients')} Icon={<HailOutlined />} to="/clients_and_trainings" />}
                  {isProfessor && <ListItemAction disabled={true} label={t('Navbar.programs')} Icon={<AssignmentOutlined />} to="/programs" />}
                  {isClient && <ListItemAction disabled={true} label={t('Navbar.trainings')} Icon={<InsightsIcon />} to="/history" />}
                  <ListItemAction disabled={true} label={t('Navbar.classes')} Icon={<ClassOutlined />} to="/training_classes" />
                </List>
              </Box>

              <Divider />
              <List onClick={() => setIsDrawerOpen(false)} sx={{ bgcolor: 'background.paper', '> a': { textDecoration: 'none', color: 'inherit' } }}>
              <ListItemAction label={t('Navbar.billing')} Icon={<CreditCardOutlined />} to="/billing" />
              <ListItemAction label={t('Navbar.packages')} Icon={<LocalMallOutlined />} to="/packages" />
              <ListItemAction label={t('Navbar.profile')} Icon={<AccountBoxOutlined />} to="/about" />
              </List>
            </>
            ) : (
              <Box display="flex" flexDirection="column" alignItems="center">
                <Divider />
                <Typography sx={{ margin: '10px 20px' }} variant="body1">{t('Navbar.knowWizfitPremiumVersion')}</Typography>
                <Divider />

                <a href="https://wizfit.ai" target="_blank" rel="noreferrer" >
                  <Button  variant="outlined" color="primary" size="large" sx={{ backgroundColor: 'white' }}>
                    Wizfit.ai
                  </Button>
                </a>
                <Divider />
                <Box mt={2}>
                  <Link to="sign-in"><Typography color="primary" variant="subtitle1" sx={{ textDecoration: 'underline'}}>{t('Navbar.iHaveAccount')}</Typography></Link>
                </Box>
              </Box>
            )}
          </SwipeableDrawer>
        </>
        )}
    </>
  );
};



export default Navbar;
