import { Typography, Box } from '@mui/material';
import React from 'react';

export const BigArrow = ({ color = '#9B72FE', height = '503', width = '508' }) => {
  return (
    <Box width={`${width}px`} height={`${height}px`} sx={{ alignContent: 'center', transition: '0.2s all ease-in' }} display="flex" flexDirection="column" alignItems="center">
      <svg width="508" height="503" viewBox="0 0 508 503" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M323 0.134022C318.594 0.809802 315.065 3.87504 313.736 5.5C261.069 69.1667 153.936 198.4 146.736 206C137.736 215.5 139.236 221 142.736 228.5C145.536 234.5 156.236 233.667 161.236 232.5L323.868 206.141L486.5 232.5C491.5 233.667 502.2 234.5 505 228.5C508.5 221 510 215.5 501 206C493.8 198.4 386.667 69.1667 334 5.5C332.671 3.87504 329.141 0.809802 324.736 0.134022V0C324.443 0 324.154 0.0115769 323.868 0.033755C323.582 0.0115769 323.292 0 323 0V0.134022Z" fill={color}/>
        <path d="M194.813 371C158.013 423.8 75.4794 448.333 38.8127 454C-12.6873 454 -13.1873 489 38.8127 495.5C90.8127 502 185.813 521.5 287.313 440C368.513 374.8 402.813 264.833 409.813 218L355.813 175L275.813 185.5L246.813 222.5C244.813 250 231.613 318.2 194.813 371Z" fill={color}/>
      </svg>
    </Box>
  );
}
