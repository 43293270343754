import React, { useContext, useEffect, useState } from 'react';
import { Backdrop, Card, CardContent, Typography, CardActions, Button, Dialog, DialogTitle, DialogContent, DialogActions, Paper, Box, Slide, List, Alert, SpeedDial, SpeedDialIcon } from '@mui/material';
import api, { Gym, User, WorkoutWeek } from '../../api';
import { useTranslation } from 'react-i18next';
import Transition from '../common/Transition';
import { GymCard } from '../profile/GymCard';
import { AssignmentOutlined, InfoOutlined, Widgets } from '@mui/icons-material';
import ReactDOM from 'react-dom';
import { ListItemAction } from '../Navbar';
import './effects.css';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import CountUpDown from '../common/CountUpDown';
import { UserContext } from '../../contexts/userContext';
import TutorialRelax from '../../icons/tutorialRelax';
import { BigArrow } from '../../icons/big-arrow';

interface AddPlanificationTutorialProps {
  gym?: Gym;
  hasPlanifications?: boolean;
  first?: WorkoutWeek | null;
}

const AddPlanificationTutorial: React.FC<AddPlanificationTutorialProps> = ({ gym, hasPlanifications, first }) => {
  const [step, setStep] = React.useState('initial');
  const [shownCredits, setShownCredits] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getUser } = useContext(UserContext);

  const handleFinishTutorial = async () => {
    if (!gym) { return; }
    setStep('initial');
    setShownCredits(true);
    await api.gyms.setTutorialStep(gym.id, 'firstPlanification');
    await getUser();
  }

  useEffect(() => {
    console.log('gym tutorial', gym?.tutorialSteps)
    if (!gym) { return; }
    if (!gym?.tutorialSteps?.firstPlanification) {
      setStep('start');
      return;
    }
    const finishedTutorialDate = dayjs(gym.tutorialSteps.firstPlanification.date);
    if (Math.abs(finishedTutorialDate.diff(dayjs(), 'seconds')) < 10 && !shownCredits) {
      setStep('congratulations')
    }
  }, [gym]);

  return (
    <>
      {step !== 'initial' && (
        <Backdrop open sx={{ zIndex: 10000 }}>
          {step === 'start' && (
            <>
            
            
              <Dialog open hideBackdrop sx={{ zIndex: 10000 }}>
                <Box display="flex" m={3} flexDirection={['column', 'row']}>
                  <Box flex={1} position="relative">
                    <TutorialRelax height={160} />
                  </Box>
                  <Box flex={4}>
                    <DialogTitle>{t('Tutorial.firstPlanificationTitle')}</DialogTitle>
                    <DialogContent>
                      {t('Tutorial.firstPlanificationDescription')}
                      <Typography variant="body1">{t('Tutorial.firstPlanificationCreate')}</Typography>
                    </DialogContent>
                    
                    <DialogActions>
                      <Button onClick={() => setStep('initial')}>
                        {t('Tutorial.understood')}
                      </Button>
                    </DialogActions>
                      <Box sx={{ position: 'fixed', bottom: 50, right: 80, transform: 'rotate(90deg)', transition: '2.5 all ease-in' }}>
                        <BigArrow width={'120'} height={'120'} />
                      </Box>
                      <SpeedDial
                        onClick={() => navigate("/workoutweeks/add")}
                        ariaLabel="create workoutweek"
                        icon={<SpeedDialIcon />}
                        className="glow-effect-circle"
                        sx={{ bottom: 75, right: 16, position: 'fixed', zIndex: 10002, '> button': { marginTop: '-15px' }} }
                      />
                  </Box>
                </Box>
              </Dialog>
          </>
          )}
          {step === 'congratulations' && (
            <>
            
            
              <Dialog open hideBackdrop sx={{ zIndex: 10000 }}>
                <DialogTitle>{t('Tutorial.firstPlanificationCongratulationsTitle')}</DialogTitle>
                <DialogContent>
                  {t('Tutorial.firstPlanificationCongratulationsDescription')}
                  <Box my={3}>
                    <CountUpDown start={0} end={10} duration={1000} label={t('Tutorial.newCredits')} />
                  </Box>
                  <Typography variant="body1">{t('Tutorial.firstPlanificationCongratulationsDescription2')}</Typography>
                  
                </DialogContent>
                
                <DialogActions>
                  <Button onClick={handleFinishTutorial}>
                    {t('Tutorial.understood')}
                  </Button>
                </DialogActions>
              </Dialog>
          </>
          )}
         </Backdrop>
      )}
    </>
  );
};

export default AddPlanificationTutorial;