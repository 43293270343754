export const getColorByEffort = (effort: number) => {
  if (effort <= 60) {
    return '#9ACD32';
  } else if (effort <= 70) {
    return '#FFA500';
  } else if (effort <= 80) {
    return '#B22222';
  } else {
    return '#9400D3';
  }
}

export const getColorByLevel = (level?: string) => {
  switch (level) {
    case 'BEGINNER':
      return '#9ACD32';
    case 'MEDIUM':
      return '#FFA500';
    case 'ADVANCED':
      return '#B22222';
    default:{
      console.log('couldnt find color for level', level);
      return '#000';
    }
  }
}