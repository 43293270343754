import { Typography, Box } from '@mui/material';
import React from 'react';

export const HandGrab = ({ height = '36', width = '41' }) => {
  return (
    <Box width={`${width}px`} height={`${height}px`} sx={{ alignContent: 'center', transition: '0.2s all ease-in' }} display="flex" flexDirection="column" alignItems="center">
     <svg width="41" height="36" viewBox="0 0 41 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_32_2400)">
<path d="M28.2371 5.81L28.25 6.2L30.8333 6.2C31.5185 6.2 32.1756 6.47393 32.66 6.96152C33.1445 7.44912 33.4167 8.11044 33.4167 8.8C33.4167 9.48956 33.1445 10.1509 32.66 10.6385C32.1756 11.1261 31.5185 11.4 30.8333 11.4L33.4167 11.4C34.1018 11.4 34.7589 11.6739 35.2434 12.1615C35.7278 12.6491 36 13.3104 36 14C36 14.6896 35.7278 15.3509 35.2434 15.8385C34.7589 16.3261 34.1018 16.6 33.4167 16.6L30.8333 16.6C31.5185 16.6 32.1756 16.8739 32.66 17.3615C33.1445 17.8491 33.4167 18.5104 33.4167 19.2C33.4167 19.8896 33.1445 20.5509 32.66 21.0385C32.1756 21.5261 31.5185 21.8 30.8333 21.8L28.25 21.8C28.9351 21.8 29.5922 22.0739 30.0767 22.5615C30.5612 23.0491 30.8333 23.7104 30.8333 24.4C30.8333 25.0896 30.5612 25.7509 30.0767 26.2385C29.5922 26.7261 28.9351 27 28.25 27L17.9167 27C14.491 27 11.2055 25.6304 8.7832 23.1924C6.36086 20.7544 5 17.4478 5 14L5 12.7C5 9.59697 6.22478 6.62102 8.40489 4.42685C10.585 2.23267 13.5419 0.999999 16.625 0.999999L23.0833 1C24.3868 0.999585 25.6423 1.49506 26.5981 2.38709C27.5539 3.27913 28.1393 4.5018 28.2371 5.81ZM30.8333 6.2L23.0833 6.2L30.8333 6.2ZM33.4167 11.4L28.25 11.4L33.4167 11.4ZM33.4167 16.6L28.25 16.6L33.4167 16.6ZM30.8333 21.8L25.6667 21.8L30.8333 21.8Z" fill="white"/>
<path d="M30.8333 6.2L28.25 6.2L28.2371 5.81C28.1393 4.5018 27.5539 3.27913 26.5981 2.38709C25.6423 1.49506 24.3868 0.999585 23.0833 1L16.625 0.999999C13.5419 0.999999 10.585 2.23267 8.40489 4.42685C6.22478 6.62102 5 9.59697 5 12.7L5 14C5 17.4478 6.36086 20.7544 8.7832 23.1924C11.2055 25.6304 14.491 27 17.9167 27L28.25 27C28.9351 27 29.5922 26.7261 30.0767 26.2385C30.5612 25.7509 30.8333 25.0896 30.8333 24.4C30.8333 23.7104 30.5612 23.0491 30.0767 22.5615C29.5922 22.0739 28.9351 21.8 28.25 21.8L30.8333 21.8M30.8333 6.2C31.5185 6.2 32.1756 6.47393 32.66 6.96152C33.1445 7.44912 33.4167 8.11044 33.4167 8.8C33.4167 9.48956 33.1445 10.1509 32.66 10.6385C32.1756 11.1261 31.5185 11.4 30.8333 11.4L33.4167 11.4M30.8333 6.2L23.0833 6.2M33.4167 11.4C34.1018 11.4 34.7589 11.6739 35.2434 12.1615C35.7278 12.6491 36 13.3104 36 14C36 14.6896 35.7278 15.3509 35.2434 15.8385C34.7589 16.3261 34.1018 16.6 33.4167 16.6M33.4167 11.4L28.25 11.4M33.4167 16.6L30.8333 16.6C31.5185 16.6 32.1756 16.8739 32.66 17.3615C33.1445 17.8491 33.4167 18.5104 33.4167 19.2C33.4167 19.8896 33.1445 20.5509 32.66 21.0385C32.1756 21.5261 31.5185 21.8 30.8333 21.8M33.4167 16.6L28.25 16.6M30.8333 21.8L25.6667 21.8" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<filter id="filter0_d_32_2400" x="0.5" y="0.5" width="40" height="35" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="4"/>
<feGaussianBlur stdDeviation="2"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_32_2400"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_32_2400" result="shape"/>
</filter>
</defs>
</svg>

    </Box>
  );
}
