import React, { useState } from 'react';
import { Alert, Box, Button, CircularProgress, IconButton } from '@mui/material';
import { AddOutlined, ReplayOutlined } from '@mui/icons-material';
import api, { Exercise } from '../../api';
import { useWizard } from '../../contexts/PlanifyWizardContext';
import { useTranslation } from 'react-i18next';

type Props = {
  dayIndex: number;
  exerciseIndex: number,
  exercise?: Exercise;
  onReplace?: (day: number, exercise: number, data: Exercise) => void;
};

const AddExerciseButton: React.FC<Props> = ({ dayIndex, exerciseIndex, exercise, onReplace }) => {
  const { result, setResult } = useWizard();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { t } = useTranslation();

  const handleReplaceExercise = async () => {
    const user = result?.user;
    if (!user?.clientId) {
      setError(t('WorkoutweekEditor.replaceExerciseNotAvailable'));
      setTimeout(() => setError(null), 3500);
      return;
    }
    if (!result || !onReplace || !exercise || !user?.clientId) return;
    setLoading(true);
    setError(null);

    try {
      // const exercises = result.planification[dayIndex].exercises;
      const res = await api.openai.generateExercise(`${user.clientId}`, exercise);
      if (res.success && res.data) {
        onReplace(dayIndex, exerciseIndex, res.data);

      }
      // Handle successful response
    } catch (error: any) {
      setError(error.toString());
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box  width={"100%"} textAlign={"center"} position="relative">
      <IconButton color="secondary" onClick={handleReplaceExercise} disabled={loading}>
        {!loading ? <ReplayOutlined /> : <CircularProgress size={20}  sx={{ ml: 2 }}/>}
      </IconButton>
      {error && <Alert severity="info" sx={{ position: 'absolute', left: -200 }}>{error}</Alert>}
    </Box>
  );
};

export default AddExerciseButton;