import React, { ReactElement, FC, useEffect, useState, useRef, useContext } from "react";
import { Box, Button, Typography, Alert, CircularProgress, AlertTitle, Grow, IconButton, ListItemText, AccordionSummary, Accordion, AccordionDetails, Chip, List, ListItem, ListItemAvatar, ListItemButton, Badge, useTheme } from "@mui/material";
import RoomIcon from '@mui/icons-material/Room';
import api, { MultipleTraining, ProcessStatus, WorkoutWeek, WorkoutWeekDraft } from "../api";
import { Link, useNavigate, useParams } from "react-router-dom";
import TrainingProgramDetailsComponent from "../components/TrainingDetails";
import { PageTitle } from "../components/PageTitle";
import { UserContext } from "../contexts/userContext";
import LogoLoading from "../icons/logoLoading";
import WorkoutWeekEditor from "../components/workweek-editor/WorkweekEditor";
import { useWizard, WizardContextProvider } from "../contexts/PlanifyWizardContext";
import { stubPlanificationExercises } from "../utils/json.extract";
import { useTranslation } from "react-i18next";

const loadingMessages = [
  "LoadingMessages.loadingData",
  "LoadingMessages.generatingMagic",
  "LoadingMessages.pleaseWait",
  "LoadingMessages.almostThere",
  "LoadingMessages.getReady",
  "LoadingMessages.aLittlePatience",
  "LoadingMessages.weAreOnIt",
  "LoadingMessages.dontGoAway",
  "LoadingMessages.soonResults",
  "LoadingMessages.fineTuning",
  "LoadingMessages.adjustingFinalPieces",
  "LoadingMessages.somethingIncredible",
  "LoadingMessages.unfoldingSurprises",
  "LoadingMessages.takeABreath",
  "LoadingMessages.worthTheWait",
  "LoadingMessages.puttingInOrder",
  "LoadingMessages.newsSoon",
  "LoadingMessages.loadingExcitement",
  "LoadingMessages.oneMoreSecond",
  "LoadingMessages.justAMoment",
];

type MultipleTrainingsState = {
  data?: MultipleTraining,
  workoutWeekDraft?: WorkoutWeekDraft,
  status: string,
  error?: string | boolean,
}

const MultipleTrainingsDraftPage: FC<any> = (): ReactElement => {
  const containerRef = useRef<any>();
  const { id = '' } = useParams();
  const theme = useTheme();
  const { user } = useContext(UserContext);
  const { setResult, result } = useWizard();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [multipleTrainings, setMultipleTrainings] = useState<MultipleTrainingsState>({ status: "initial" });
  const [loadingMessage, setLoadingMessage] = useState(loadingMessages[Math.floor(Math.random()*100) % loadingMessages.length]);

  const getMultipleTrainings = async (id: number) => {
    setMultipleTrainings({ status: "loading" })
    const interval = setInterval(async () => {
      const multipleTrainings = await api.multipleTrainings.getById(id);
      if (multipleTrainings.data?.status === ProcessStatus.FINISHED) {
        const data = { ...multipleTrainings.data };
        const workoutWeekDraft: WorkoutWeekDraft = { 
          reasoning: '',
          motivational: '',
          user: {
            name: "",
            lastname: "",
            trainingPreferences: "",
            previousExperience: "",
            mainGoal: "",
            trainingDuration: "",
            injuries: "",
            profession: ""
          },
          ...data.planification,
          planification: stubPlanificationExercises(data.planification?.planification || []),
       };
        setMultipleTrainings({ status: "success", workoutWeekDraft, data: data });
        setResult(workoutWeekDraft);
        clearInterval(interval);
      } else {
        if (multipleTrainings.data?.status === ProcessStatus.ERRORED) {
          setMultipleTrainings({ status: "error", error: multipleTrainings.error });
          clearInterval(interval);
        } else {
          setLoadingMessage(loadingMessages[Math.floor(Math.random()*100) % loadingMessages.length]);
        }
      }
    }, 3000);
    
  }

  useEffect(() => {
    if (id) {
      getMultipleTrainings(parseInt(id));
    }
  }, [id]);
  return (
    <Box
      display={"flex"}
      flexDirection={"column"} 
      ref={containerRef}
      mb={4}
    >
      
      <Box>
        {multipleTrainings.status === 'loading' && (
          <Box display="flex" flexDirection="column" alignItems="center" width="100%">
            <LogoLoading />
            <Typography variant="h4" sx={{ marginTop: 2 }} color={theme.palette.secondary.main}>
            {t('MultipleTrainingsDraft.loadingData')}
            </Typography>
            <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
              {t(loadingMessage)}
            </Typography>
          </Box>
        )}
        {multipleTrainings.status === 'error' && (
          <Box>
            <Typography variant="h6" sx={{ marginTop: 2 }}>
            {t('MultipleTrainingsDraft.errorProcessing')}
            </Typography>
            <Typography variant="subtitle1" sx={{ marginTop: 2 }}>
              {multipleTrainings.error}
            </Typography>
          </Box>
        )}
      </Box>

      {multipleTrainings.status === 'success' && result && (
        <Box>
          <WorkoutWeekEditor workoutWeek={result} multipleTrainingId={multipleTrainings.data?.id}/>
        </Box>
      )}
    </Box>
  );
};

const MultipleTrainingsDraftPageContainer: FC<any> = (): ReactElement => {
  return (
    <WizardContextProvider>
      <MultipleTrainingsDraftPage />
    </WizardContextProvider>
  );
};

export default MultipleTrainingsDraftPageContainer;