import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Button, useTheme } from '@mui/material';
import { useWizard } from '../../contexts/PlanifyWizardContext';
import { UserContext } from '../../contexts/userContext';
import { WorkspacePremiumOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const Step6: React.FC = () => {
  const [trainingFrequency, setTrainingFrequency] = useState<number>(2);
  const [trainingDuration, setTrainingDuration] = useState<string>('60');
  const { user } = useContext(UserContext);
  const { setData, data } = useWizard();
  const theme = useTheme();
  const { t } = useTranslation();

  const handleFrequencyChange = (event: SelectChangeEvent) => {
    setTrainingFrequency(parseInt(event.target.value));
  };

  const handleDurationChange = (event: SelectChangeEvent) => {
    setTrainingDuration(event.target.value as string);
  };

  useEffect(() => {
    setData({ ...data, availableDays: trainingFrequency, trainingDuration: trainingDuration });
  }, [ trainingFrequency, trainingDuration ]);
  const premium = user?.id ? true : false;

  return (
    <Box>
      {premium ? (
        <Typography variant="h6" sx={{ marginBottom: 2 }}>
          {t('WizardStep6.trainingFrequency')}
      </Typography>
      ) : (<Typography variant="h6" sx={{ marginBottom: 2 }}>
        {t('WizardStep6.trainingFrequency')}
        {t('WizardStep6.forThis')} <span style={ { textDecoration: 'underline' }}>{t('WizardStep6.freeTry')}</span>{t('WizardStep6.weOffer2Times')}
      </Typography>
      )}
      <Box display="flex" justifyContent="space-between" gap={2}>
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <InputLabel id="training-frequency-label">{t('GoalForm.weeklyFreq')}</InputLabel>
          <Select
            labelId="training-frequency-label"
            id="training-frequency"
            value={`${trainingFrequency}`}
            label={t('GoalForm.weeklyFreq')}
            onChange={handleFrequencyChange}
          >
            <MenuItem value={1} disabled={!premium}>{t('GoalForm.oneTimePerWeek')}  <WorkspacePremiumOutlined sx={{ ml: 2, display: premium ? 'none' : 'inherit' }}/></MenuItem>
            <MenuItem value={2} >{t('GoalForm.xTimesPerWeek', { x: 2 })} </MenuItem>
            <MenuItem value={3} disabled={!premium}>{t('GoalForm.xTimesPerWeek', { x: 3 })} <WorkspacePremiumOutlined sx={{ ml: 2, display: premium ? 'none' : 'inherit' }}/></MenuItem>
            <MenuItem value={4} disabled={!premium}>{t('GoalForm.xTimesPerWeek', { x: 4 })} <WorkspacePremiumOutlined sx={{ ml: 2, display: premium ? 'none' : 'inherit' }}/></MenuItem>
            <MenuItem value={5} disabled={!premium}>{t('GoalForm.xTimesPerWeek', { x: 5 })} <WorkspacePremiumOutlined sx={{ ml: 2, display: premium ? 'none' : 'inherit' }}/></MenuItem>
            <MenuItem value={6} disabled={!premium}>{t('GoalForm.xTimesPerWeek', { x: 6 })} <WorkspacePremiumOutlined sx={{ ml: 2, display: premium ? 'none' : 'inherit' }}/></MenuItem>
            <MenuItem value={7} disabled={!premium}>{t('GoalForm.xTimesPerWeek', { x: 7 })}<WorkspacePremiumOutlined sx={{ ml: 2, display: premium ? 'none' : 'inherit' }}/></MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <InputLabel id="training-duration-label">{t('GoalForm.trainingDuration')}</InputLabel>
          <Select
            labelId="training-duration-label"
            id="training-duration"
            value={trainingDuration}
            label={t('GoalForm.trainingDuration')}
            onChange={handleDurationChange}
          >
            <MenuItem value={"30"}>{t('GoalForm.30minutes')}</MenuItem>
            <MenuItem value={"45"}>{t('GoalForm.45minutes')}</MenuItem>
            <MenuItem value={"60"}>{t('GoalForm.60minutes')}</MenuItem>
            <MenuItem value={"75"}>{t('GoalForm.75minutes')}</MenuItem>
            <MenuItem value={"90"}>{t('GoalForm.90minutes')}</MenuItem>
            <MenuItem value={"105"}>{t('GoalForm.105minutes')}</MenuItem>
            <MenuItem value={"120"}>{t('GoalForm.120minutes')}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      {premium ? (
        <Box>
          <Box width="100%" display="flex" flexDirection="column" alignItems="center">
            <Box display="flex" flexDirection="column" mt={['200px', '200px']} alignItems="center" maxWidth={['100%', '300px']} padding={[3, 4]} borderRadius={'20px'} 
              sx={{ 
                background: theme.palette.grey[200],
              }}>
              <Typography variant="body1" textAlign="center" fontWeight="bold" sx={{ marginBottom: 3 }}>
              {t('WizardStep6.byBeingPremiumYouMaySpecify')}
                <a href="mailto:contacto@wizfit.ai">contacto@wizfit.ai</a>
              </Typography>

            </Box>
          </Box>
        </Box>
      ) : (
        <Box width="100%" display="flex" flexDirection="column" alignItems="center">
          <Box display="flex" flexDirection="column" mt={['200px', '200px']} alignItems="center" maxWidth={['100%', '300px']} padding={[3, 4]} borderRadius={'20px'} 
            sx={{ 
              background: theme.palette.grey[200],
            }}>
            <Typography variant="body1" textAlign="center" fontWeight="bold" sx={{ marginBottom: 3 }}>
            {t('WizardStep6.needToSpecify')}
            </Typography>
            <a href="https://wizfit.ai/inicio/#precios" target="_blank" rel="noreferrer">
              <Button variant="outlined" sx={{ paddingTop: '5px'}}>
                {t('WizardStep6.seePremiumPrices')}
              </Button>
            </a>

          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Step6;