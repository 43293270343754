import React, { createContext, useState, useContext, useEffect } from 'react';
import api, { PlanificationUser, WorkoutWeekDraft } from '../api'; // Adjust the import according to your project structure
import { extractWorkoutWeekFromText } from '../utils/json.extract';

interface WizardContextProps {
  currentStep: number;
  data: PlanificationUser;
  setData: (data: PlanificationUser) => void;
  nextStep: () => void;
  prevStep: () => void;
  goToStep: (step: number) => void;
  submitWizard: () => Promise<any>;
  setResult: (result?: WorkoutWeekDraft) => void;
  result?: WorkoutWeekDraft;
}

const WizardContext = createContext<WizardContextProps | undefined>(undefined);

const WizardContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [data, setDataInternal] = useState<PlanificationUser>({
      name: '',
      lastname: '',
      // age: 0,
      // weight: 0,
      //  height: 0,
      trainingPreferences: '',
      previousExperience: '',
      mainGoal: '',
      // availability_days_per_week: 0,
      trainingDuration: '',
      injuries: '',
      profession: ''
  });
  const [result, setResultInternal] = useState<any>();

  const nextStep = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };
  const setData = (data: PlanificationUser) => {
    setDataInternal(data);
    console.log('data', data)
  };

  const setResult = (result: any) => {
    setResultInternal(result);
  };
  const prevStep = () => {
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const goToStep = (step: number) => {
    if (step >= 0) {
      setCurrentStep(step);
    }
  };

  const submitWizard = async () => {
    try {
      // Call your API here
      const res = await api.openai.planify(JSON.stringify(data), !data.clientId);
      console.log('Wizard submitted successfully');
      return res;
    } catch (error) {
      console.error('Error submitting wizard:', error);
      return {
        success: false,
        error: 'Error submitting wizard',
        data: {},
      }
    }
  };

  return (
    <WizardContext.Provider value={{ currentStep, nextStep, prevStep, goToStep, submitWizard ,data, setData, result, setResult }}>
      {children}
    </WizardContext.Provider>
  );
};

const useWizard = (): WizardContextProps => {
  const context = useContext(WizardContext);
  if (!context) {
    throw new Error('useWizard must be used within a WizardContextProvider');
  }
  return context;
};

export { WizardContextProvider, useWizard };