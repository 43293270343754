import React, { useContext, useEffect, useState } from 'react';
import { Backdrop, Card, CardContent, Typography, CardActions, Button, Dialog, DialogTitle, DialogContent, DialogActions, Paper, Box, Slide, List, Alert, SpeedDial, SpeedDialIcon, LinearProgress, Divider, Skeleton } from '@mui/material';
import api, { Gym, User } from '../../api';
import { useTranslation } from 'react-i18next';
import './effects.css';
import { useNavigate } from 'react-router-dom';
import { EditExerciseCardTutorial } from './EditExerciseCardTutorial';
import AddExerciseButton from '../workweek-editor/AddExercise';
import { UserContext } from '../../contexts/userContext';
import TutorialPuzzle from '../../icons/tutorialPuzzle';
import TutorialCatPaint from '../../icons/tutorialCatPaint';
import { BigArrow } from '../../icons/big-arrow';
import TutorialExercise from '../../icons/tutorialExercise';
import { HandGrab } from '../../icons/tutorialHandGrab';

interface EditPlanificationTutorialProps {
  gym?: Gym;
  isEditing?: boolean;
}

const EditPlanificationTutorial: React.FC<EditPlanificationTutorialProps> = ({ gym, isEditing,  }) => {
  const [step, setStep] = React.useState('initial');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getUser } = useContext(UserContext);

  const handleFinishTutorial = async () => {
    if (!gym) { return; }
    setStep('initial');
    await api.gyms.setTutorialStep(gym.id, 'firstPlanification');
    getUser();
  }
  useEffect(() => {
    console.log('gym tutorial edit planification', gym?.tutorialSteps)
    if (!gym) { return; }
    if (!gym?.tutorialSteps?.firstPlanification) {
      setStep('start');
    }
  }, []);
  useEffect(() => {
   
  }, [step])
  
  useEffect(() => {
    if (isEditing) {
      setStep('edit1');
    }
  }, [isEditing])
  
  return (
    <>
      {step !== 'initial' && (
        <Backdrop open sx={{ zIndex: 10000 }}>
          {step === 'start' && (
            <>
            
              <Dialog open hideBackdrop sx={{ zIndex: 10000 }}>
                <Box display="flex" m={3} flexDirection={['column', 'row']}>

                  
                  <Box flex={1} position="relative">
                    <TutorialPuzzle height={160} />
                    <Box sx={{ position: 'fixed', top: 100 }}>
                      <BigArrow width={'120'} height={'120'} />
                    </Box>
                  </Box>
                  <Box flex={4}>
                    <DialogTitle>{t('Tutorial.editPlanificationTitle')}</DialogTitle>
                    <DialogContent>
                      {t('Tutorial.editPlanificationDescription1')}

                      <Typography variant="body1">{t('Tutorial.editPlanificationProgress')}</Typography>
                      <Typography variant="body1">{t('Tutorial.editPlanificationDescription2')}</Typography>
                    </DialogContent>
                    
                    <DialogActions>
                      <Button color="secondary" variant="contained"  onClick={() => setStep('next')}>
                        {t('Tutorial.next')}
                      </Button>
                      <Button onClick={handleFinishTutorial}>
                        {t('Tutorial.skip')}
                      </Button>
                    </DialogActions>
                  </Box>
                </Box>
              </Dialog>
          </>
          )}
          {step === 'next' && (
            <>
            
              <Dialog open hideBackdrop sx={{ zIndex: 10000 }}>
                <Box display="flex" m={3} flexDirection={['column', 'row']}>
                  <Box flex={1} position="relative">
                    <TutorialCatPaint height={160} />
                  </Box>
                  <Box flex={4}>
                    <DialogTitle>{t('Tutorial.editPlanificationTitle')}</DialogTitle>
                    <DialogContent>
                    
                      <Typography variant="body1">{t('Tutorial.editPlanificationDescription3')}</Typography>
                    </DialogContent>
                    
                    <DialogActions>
                      <Button onClick={() => setStep('initial')}>
                        {t('Tutorial.understood')}
                      </Button>
                    </DialogActions>
                  </Box>
                </Box>
              </Dialog>
          </>
          )}

          {step === 'edit1' && (
            <>
            
              <Dialog open hideBackdrop sx={{ zIndex: 10000 }}>
                <Box display="flex" m={3} flexDirection={['column', 'row']}>
                  <Box flex={1} position="relative">
                    <TutorialCatPaint height={120} />
                  </Box>
                  <Box flex={4}>
                    <DialogTitle>{t('Tutorial.editPlanificationReadyTitle')}</DialogTitle>
                    <DialogContent>
                    
                      <Typography variant="body1">{t('Tutorial.editPlanificationReadyDnD')}</Typography>
                      <Box width="100%" display="flex" justifyContent="center">
                        <Box className="list-container">
                          <Box className="move-down" mt={1}>
                            <Skeleton variant="rectangular" width={300} height={50} />
                          </Box>
                          <Box className="list-item" mt={1}>

                            <Skeleton variant="rectangular" width={300} height={50} />
                          </Box>
                          <Box className="move-item" mt={1}>
                            <Box position="absolute" left="-20px" ><HandGrab /></Box>
                            <Skeleton variant="rectangular" width={300} height={50} />
                          </Box>
                        </Box>
                      </Box>
                      
                    </DialogContent>
                    
                    <DialogActions>
                      <Button variant="contained" color="secondary" onClick={() => setStep('edit2')}>
                        {t('Tutorial.next')}
                      </Button>
                    </DialogActions>
                  </Box>
                </Box>
              </Dialog>
                
          </>
          )}
          {step === 'edit2' && (
            <>
            
              <Dialog open hideBackdrop sx={{ zIndex: 10000 }}>
                <Box display="flex" m={3} flexDirection={['column', 'row']}>
                    <Box flex={1} position="relative">
                      <TutorialCatPaint height={120} />
                    </Box>
                    <Box flex={4}>
                      <DialogTitle>{t('Tutorial.editPlanificationReadyTitle')}</DialogTitle>
                      <DialogContent>
                      
                        <Typography variant="body1">{t('Tutorial.editPlanificationReadyExercise')}</Typography>
                        <Box mt={2}>
                          <TutorialExercise />
                        </Box>
                        
                      </DialogContent>
                      
                      <DialogActions>
                        <Button variant="contained" color="secondary" onClick={() => setStep('edit3')}>
                          {t('Tutorial.next')}
                        </Button>
                      </DialogActions>
                    </Box>
                </Box>
              </Dialog>
          </>
          )}

          {step === 'edit3' && (
            <>
            
              <Dialog open hideBackdrop sx={{ zIndex: 10000 }}>
                <Box display="flex" m={3} flexDirection={['column', 'row']}>
                      <Box flex={1} position="relative">
                        <TutorialCatPaint height={120} />
                      </Box>
                      <Box flex={4}>
                        <DialogTitle>{t('Tutorial.editPlanificationReadyTitle')}</DialogTitle>
                        <DialogContent>
                        
                          <Typography variant="body1">{t('Tutorial.editPlanificationReadyExerciseNew')}</Typography>
                          <Typography variant="body1">{t('Tutorial.editPlanificationReadyExerciseNew2')}</Typography>
                          <Box my={2}>

                            <AddExerciseButton dayIndex={1} exerciseIndex={1} />
                          </Box>
                          <Typography variant="body1">{t('Tutorial.editPlanificationReadyExerciseNew3')}</Typography>
                          
                        </DialogContent>
                        
                        <DialogActions>
                          <Button variant="contained" color="secondary" onClick={() => setStep('initial')}>
                            {t('Tutorial.understood')}
                          </Button>
                        </DialogActions>
                      </Box>
                </Box>
              </Dialog>
          </>
          )}
         </Backdrop>
      )}
    </>
  );
};

export default EditPlanificationTutorial;